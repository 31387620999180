import { Action } from "@ngrx/store";
import { WalletConnectState } from "../store/walletConnect";
import {
  SetWalletConnectConnectedAddressAction,
  SetWalletConnectNetworkAction,
  WalletConnectCallRejection,
  WalletConnectSessionRequest,
  WalletConnectStateInitSuccessAction,
  WalletConnectSetChainRequestSuccessAction,
  WalletConnectSetChainRequestFailureAction,
  WalletConnectSetSupportedNameSpaceAction,
  WalletConnectSessionProposalPairSuccessAction,
  WalletConnectSessionApproveAction,
  WalletConnectSessionRejectAction,
  WalletConnectErrorAction,
  WalletConnectSessionDisconnectSuccessAction,
  WalletConnectEventSessionRequestAction,
  WalletConnectEventRequestCancelledSuccessAction,
  WalletConnectEventRequestSignAuthRequestAction,
  WalletConnectEventRequestSignSuccessAction,
  WalletConnectSessionDisconnectedRequestFromDAppAction,
  WalletConnectSingularitySessionApproveAction,
} from "../actions/walletConnect.actions";
import { WalletConnectActionTypes } from "../actions/walletConnect.actions";
import { environment } from "../../../environments/environment";
import { Logger } from '../services/logger.service';
import { isEmpty, isNil } from "../ts-util";

const defaultChain = environment.EXTERNAL_APIS.EXPLORERS.find(id => id.id === "avax-main");

const defaultChain2= {
  chain:"Avalanche mainnet",
  chainId:43114,
  // RPC_URL: "https://shy-small-sun.avalanche-mainnet.quiknode.pro/54189497e39a642e32d57998bfd7b6bd1dfc401f/ext/bc/C/rpc",
  RPC_URL: "https://avalanche-c-chain-rpc.publicnode.com",
}

export const initialWalletConnectState = {
  web3Wallet:null,
  web3WalletNonce: 0,
  walletConnectSelectedNetwork: {
    name: defaultChain2.chain,
    chainId: defaultChain2.chainId,
    rpcUrl: defaultChain2.RPC_URL,
    baseChainSymbol: defaultChain2.chain,
    walletAddress: '',
  }, //TODO: get from a config
  walletConnectConnectedAddress: "",
  session: null,
  singularitySession: null,
  connectors: [],
  peerMeta: {
    description: "",
    url: "",
    icons: [],
    name: "",
    ssl: false
  },
  payload: null,

 
  approvalSignatures: [],
  rejectionErrors: [],
  lastTransaction: null,
  transactionHistory: [],

  signingRequest: null,


  eventRequests: [],
  supportedNameSpaces: new Map(),
  loading: false,
  message: '',
  error: false,
};

export function walletConnectReducer(state: WalletConnectState = initialWalletConnectState, action: Action): WalletConnectState {
  let payload;

  switch (action.type) {
    case WalletConnectActionTypes.WALLETCONNECT_STATE_INIT_SUCCESS:
      return handleWalletConnectStateInitSuccessAction(state, action as WalletConnectStateInitSuccessAction);
    
    case WalletConnectActionTypes.WALLETCONNECT_SET_CHAIN_REQUEST_SUCCESS:
      return handleWalletConnectSetChainSuccessAction(state, action as WalletConnectSetChainRequestSuccessAction);
    
    case WalletConnectActionTypes.WALLETCONNECT_SET_CHAIN_REQUEST_FAILURE:
      return handleWalletConnectSetChainFailureAction(state, action as WalletConnectSetChainRequestFailureAction);
    
    case WalletConnectActionTypes.WALLETCONNECT_SESSION_PROPOSAL_PAIR_SUCCESS:
      return handleWalletConnectSessionProposalPairSuccessAction(state, action as WalletConnectSessionProposalPairSuccessAction);
    
    case WalletConnectActionTypes.WALLETCONNECT_ERROR_ACTION:
      return handleWalletConnectErrorAction(state, action as WalletConnectErrorAction);
    
    case WalletConnectActionTypes.WALLETCONNECT_SESSION_APPROVE:
      return handleWalletConnectSessionApproveAction(state, action as WalletConnectSessionApproveAction);
    
    case WalletConnectActionTypes.WALLETCONNECT_SESSION_REJECT:
      return handleWalletConnectSessionRejectAction(state, action as WalletConnectSessionRejectAction);
    
    case WalletConnectActionTypes.WALLETCONNECT_SESSION_DISCONNECT_SUCCESS:
      return handleWalletConnectSessionDisconnectSuccessAction(state, action as WalletConnectSessionDisconnectSuccessAction);
    case WalletConnectActionTypes.WALLETCONNECT_SESSION_DISCONNECTED_REQUEST:
      return handleWalletConnectSessionDisconnectedRequestFromDappAction(state, action as WalletConnectSessionDisconnectedRequestFromDAppAction);

    case WalletConnectActionTypes.WALLETCONNECT_SET_SUPPORTED_NAMESPACE_REQUEST:
      return handleWalletConnectSetSupportedNameSpaceAction(state, action as WalletConnectSetSupportedNameSpaceAction);


    case WalletConnectActionTypes.WALLETCONNECT_EVENT_SESSION_REQUEST:
      return handleWalletConnectEventSessionRequestAction(state, action as WalletConnectEventSessionRequestAction);
    case WalletConnectActionTypes.WALLETCONNECT_EVENT_REQUEST_SIGN_AUTH_REQUEST:
      return handleWalletConnectEventSessionRequestSignAuthAction(state, action as WalletConnectEventRequestSignAuthRequestAction);
    case WalletConnectActionTypes.WALLETCONNECT_EVENT_REQUEST_SIGN_SUCCESS:
      return handleWalletConnectEventSessionRequestSignSuccessAction(state, action as WalletConnectEventRequestSignSuccessAction);
    case WalletConnectActionTypes.WALLETCONNECT_EVENT_REQUEST_CANCELLED_SUCCESS:
      return handleWalletConnectEventRequestCancelledSuccessAction(state, action as WalletConnectEventRequestCancelledSuccessAction);
    
    case WalletConnectActionTypes.WALLETCONNECT_SINGULARITY_SESSION_PROPOSAL_REQUEST_APPROVAL:
      return handleWalletConnectSingularitySessionApprovalAction(state, action as WalletConnectSingularitySessionApproveAction);

    

     //Todo Eric old code and need to refactor 

    case WalletConnectActionTypes.SET_BASE_CURRENCY:
      payload = (action as SetWalletConnectNetworkAction).payload;
      return { ...state, walletConnectSelectedNetwork: payload.walletConnectSelectedNetwork };
    case WalletConnectActionTypes.SET_CONNECTED_ADDRESS:
      payload = (action as SetWalletConnectConnectedAddressAction).payload;
      return { ...state, walletConnectConnectedAddress: payload.walletConnectConnectedAddress };
    case WalletConnectActionTypes.WALLETCONNECT_SESSION_REJECTION:
      payload = (action as WalletConnectCallRejection).payload;
      return {
        ...state
      };
    case WalletConnectActionTypes.WALLETCONNECT_SESSION_REQUEST:
      payload = (action as WalletConnectSessionRequest).payload;
      return {
        ...state,
        //replace connector with same handShakeId
        peerMeta: payload?.peerMeta,
        payload: payload?.payload
      };
    default:
      return state;
  }

  function handleWalletConnectStateInitSuccessAction(state: WalletConnectState, action: WalletConnectStateInitSuccessAction) {
    return {...state, web3Wallet: action.payload.web3Wallet};
  }

  function handleWalletConnectSessionProposalPairSuccessAction(state: WalletConnectState, action: WalletConnectSessionProposalPairSuccessAction) {
    
    let pre = state.web3WalletNonce ?? 0;
    pre++;
    return {...state,
      web3Wallet: action.payload.web3Wallet,
      web3WalletNonce: pre,
      loading: false,
      error: false,
    };
  }

  function handleWalletConnectErrorAction(state: WalletConnectState, action: WalletConnectErrorAction) {
    return {...state, 
      message: action.payload,
      loading: false,
      error: true,
    };
  }

function handleWalletConnectSessionApproveAction(state: WalletConnectState, action: WalletConnectSessionApproveAction) {
  let pre = state.web3WalletNonce ?? 0;
    pre++;
  return {...state,
    web3Wallet: action.payload.web3Wallet,
    web3WalletNonce: pre,
    session: action.payload.session,
    eventRequests: action.payload.eventRequests || [],
    loading: false,
  }
}
    
function handleWalletConnectSessionRejectAction(state: WalletConnectState, action: WalletConnectSessionRejectAction) {
  let pre = state.web3WalletNonce ?? 0;
  pre++;
  return {...state,
    web3Wallet: action.payload.web3Wallet,
    web3WalletNonce: pre,
    session: null,
    message: action.payload.message,
    loading: false,
  }
}

function handleWalletConnectSessionDisconnectSuccessAction(state: WalletConnectState, action: WalletConnectSessionDisconnectSuccessAction) {
  /*let activeSessions = action.payload.web3Wallet.getActiveSessions();
  let session = null;
  if (!isEmpty(activeSessions)) {
    let nextActiveSession = Object.entries(activeSessions).entries[0]; // we take the first active sessions as default active sessions;
    let id = nextActiveSession.key;
    let connectedSession = nextActiveSession.value;
    // accounts = ['eip155:43114:0xCC...']
    let accountInfo = connectedSession?.namespaces?.eip155?.accounts[0]?.split(':');
    session = {
      connected: true,
      accounts: isNil(accountInfo)? [] : [accountInfo[2]], 
      chainId: isNil(accountInfo)? 0 : Number(accountInfo[1]),
      bridge:connectedSession.relay.protocol,
      key: connectedSession.controller,
      clientId: connectedSession.self.publicKey,
      clientMeta: connectedSession.self.metadata,
      peerId: connectedSession.peer.publicKey,
      peerMeta: connectedSession.peer.metadata,
      handshakeId: id,
      handshakeTopic: connectedSession.topic
    }
    Logger.info(`handleWalletConnectSessionDisconectSuccessAction - next session ${session}`);
  } */
  let pre = state.web3WalletNonce ?? 0;
  pre++;
  let session = action.payload.sessionTopic== state.session?.handshakeTopic ? null: state.session;
  let singularitySession = action.payload.sessionTopic == state.singularitySession?.handshakeTopic ? null: state.singularitySession;
  return  {...state,
    web3Wallet: action.payload.web3Wallet,
    web3WalletNonce: pre,
    session,
    singularitySession
  }
}

  function handleWalletConnectSessionDisconnectedRequestFromDappAction(state: WalletConnectState, action: WalletConnectSessionDisconnectedRequestFromDAppAction) {
    /*
    let web3Wallet = action.payload.web3Wallet;
    let activeSessions = web3Wallet.getActiveSessions();
    let session = null;
    if (!isEmpty(activeSessions)) {
      let id;
      let nextActiveSession;
      Object.entries(activeSessions).map(([key, value]) => {
        id = key;
        nextActiveSession = value;
      })
      let connectedSession = nextActiveSession?.value;
      // accounts = ['eip155:43114:0xCC...']
      let accountInfo = connectedSession?.namespaces?.eip155?.accounts[0]?.split(':');
      session = {
        connected: true,
        accounts: isNil(accountInfo)? [] : [accountInfo[2]], 
        chainId: isNil(accountInfo)? 0 : Number(accountInfo[1]),
        bridge:connectedSession?.relay?.protocol,
        key: connectedSession?.controller,
        clientId: connectedSession?.self?.publicKey,
        clientMeta: connectedSession?.self?.metadata,
        peerId: connectedSession?.peer?.publicKey,
        peerMeta: connectedSession?.peer?.metadata,
        handshakeId: id,
        handshakeTopic: connectedSession?.topic
      }
      Logger.info(`handleWalletConnectSessionDisconnectedRequestFromDappAction - next session ${session}`);
    } z*/
    let pre = state.web3WalletNonce ?? 0;
    pre++;

    let session = action.payload.sessionTopic == state.session?.handshakeTopic ? null: state.session;
    let singularitySession = action.payload.sessionTopic == state.singularitySession?.handshakeTopic ? null: state.singularitySession;
    return  {...state,
      web3Wallet: action.payload.web3Wallet,
      web3WalletNonce: pre,
      session,
      singularitySession
    }
  }


  function handleWalletConnectEventSessionRequestAction(state: WalletConnectState, action: WalletConnectEventSessionRequestAction) {
    let eventRequests = state.eventRequests || [];
    let newEventRequest = action.payload.eventRequest;
    if (!eventRequests.includes(newEventRequest)) {
      eventRequests.push(action.payload.eventRequest);
    }
    return {...state, eventRequests}
  }

  function handleWalletConnectEventSessionRequestSignAuthAction(state: WalletConnectState, action: WalletConnectEventRequestSignAuthRequestAction) {
    return {...state,
      signingRequest: action.payload
    }
  }

  function handleWalletConnectEventSessionRequestSignSuccessAction(state: WalletConnectState, action: WalletConnectEventRequestSignSuccessAction) {
    let eventRequests = state.eventRequests || [];
    if (!isEmpty(eventRequests)) {
      eventRequests.forEach ((eventRequest, index) => {
        if (eventRequest?.id === action.payload.signedEventRequest?.id) {
          eventRequests.splice(index, 1);
        }
      })
      Logger.info(`test - ${eventRequests}`)
    }
    let pre = state.web3WalletNonce ?? 0;
    pre++;
    return {...state,
      web3Wallet: action.payload.web3Wallet,
      web3WalletNonce: pre,
      eventRequests}
  }




  function handleWalletConnectSetChainSuccessAction(state: WalletConnectState, action: WalletConnectSetChainRequestSuccessAction) {
    
    return {...state,
      walletConnectSelectedNetwork: action.payload.walletConnectSelectedNetwork,
      session: null,
      loading: false,
      error: false,
    }
  }

  function  handleWalletConnectEventRequestCancelledSuccessAction(state: WalletConnectState, action: WalletConnectEventRequestCancelledSuccessAction) {
    let eventRequests = state.eventRequests || [];
    if (!isEmpty(eventRequests)) {
      eventRequests.forEach ((eventRequest, index) => {
        if (eventRequest?.id === action.payload.eventRequestId) {
          eventRequests.splice(index, 1);
        }
      })
      Logger.info(`test - ${eventRequests}`)
    }
    let pre = state.web3WalletNonce ?? 0;
    pre++;
    return {...state,
      web3Wallet: action.payload.web3Wallet,
      web3WalletNonce: pre,
      eventRequests}
  }

  function handleWalletConnectSetChainFailureAction(state: WalletConnectState, action: WalletConnectSetChainRequestFailureAction) {
    return {...state, 
      message: action.payload,
      loading: false,
      error: true,
    };
  }

  function handleWalletConnectSetSupportedNameSpaceAction(state: WalletConnectState, action : WalletConnectSetSupportedNameSpaceAction) {
    return {...state,
      supportedNameSpaces: action.payload.supportedNameSpaces
    }
  }


  function handleWalletConnectSingularitySessionApprovalAction(state: WalletConnectState, action: WalletConnectSingularitySessionApproveAction) {
    let eventRequests = [];
    let newEventRequests = action.payload.eventRequests || [];  
    let existingEventRequests = state.eventRequests || [];

    if (isEmpty(newEventRequests)) {
      eventRequests = existingEventRequests;
    } else if (isEmpty(existingEventRequests)) {
      eventRequests = newEventRequests;
    } else {
      eventRequests = existingEventRequests;
      let existEventRequestIds = new Set();
      existingEventRequests.forEach((eventRequest, index) => {
        existEventRequestIds.add(eventRequest?.id);
      })
      newEventRequests.forEach((newEventRequest, index) => {
        if (!existEventRequestIds.has(newEventRequest?.id)) {
          eventRequests.push(newEventRequest);
        }
      })
    }
    let pre = state.web3WalletNonce ?? 0;
    pre++;
    if (isNil(state.session)) { // if no active session -> make default Singularity action
      return {...state,
        session: action.payload.singularitySession,
        web3Wallet: action.payload.web3Wallet,
        web3WalletNonce: pre,
        singularitySession: action.payload.singularitySession,
        eventRequests: eventRequests,
        loading: false,
      }
    } else {
      return {...state,
        web3Wallet: action.payload.web3Wallet,
        web3WalletNonce: pre,
        singularitySession: action.payload.singularitySession,
        eventRequests: eventRequests,
        loading: false,
      }
    }
   
  }

}




