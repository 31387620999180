import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { take, tap, withLatestFrom } from 'rxjs/operators';
import { Logger } from '../services/logger.service';
import { 
  StakingLodeStateInitRequestAction,
  StakingLodeActionTypes,
  StakingLodeStateInitSuccessAction,
  StakingLodeStateInitFailureAction,
  ApproveStakingLodeStateAction,
  ApproveStakingLodeStateSuccessAction,
  ApproveStakingLodeStateFailureAction,
  StakeStakingLodeStateAction,
  StakeStakingLodeStateSuccessAction,
  StakeStakingLodeStateFailureAction,
  LockLodeStateAction,
  LockLodeStateSuccessAction,
  LockLodeStateFailureAction,
  UnStakeStakingLodeStateAction,
  UnStakeStakingLodeStateFailureAction,
  UnStakeStakingLodeStateSuccessAction,
  ClaimStakingLodeStateAction,
  ClaimStakingLodeStateSuccessAction,
  ClaimStakingLodeStateFailureAction,
  ApproveVoteStateFailureAction,
  ApproveVoteStateSuccessAction
} from '../actions/stakingLode.actions';
import { AlertController } from '@ionic/angular';
import { StakingLodeService } from '../services/stake-lode.service';
import { inspect } from 'util';
import { SingularityUserCloseDrawer, SingularityUserLoginSuccessFromDApp } from '../actions/singularityPayment.action';
import { getPendingOrder } from '../store/purchaseOrder.state';

@Injectable()
export class  StakingLodeEffects {

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private stakingLodeService: StakingLodeService
  ) {}

  stakingLodeStateInitRequest$ = createEffect(() => this.actions$.pipe(
    ofType<StakingLodeStateInitRequestAction>(StakingLodeActionTypes.STAKING_LODE_STATE_INIT_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
       const user = await this.stakingLodeService.getUserInformation(action.address);
       return this.store.dispatch(new StakingLodeStateInitSuccessAction({ user: user }));
      } catch (err) {
        console.log('thsi hit err', err)
        Logger.error(`Error: walletConnectStateInitRequest ${inspect(err)}`);
        return this.store.dispatch(new StakingLodeStateInitFailureAction(`Failed to initiate the Wallet Connect instance. Please refresh the page`));
      }
    })
  ), { dispatch: false });



  stakingLodeApproveLodeRequest$ = createEffect(() => this.actions$.pipe(
    ofType<ApproveStakingLodeStateAction>(StakingLodeActionTypes.APPROVE_STAKING_LODE_STATE_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
       const hasApprovedLODE = await this.stakingLodeService.approveLODE(action.user);
       return this.store.dispatch(new ApproveStakingLodeStateSuccessAction({ user: hasApprovedLODE }));
      } catch (err) {
        Logger.error(`Error: walletConnectStateInitRequest ${inspect(err)}`);
        return this.store.dispatch(new ApproveStakingLodeStateFailureAction(`Failed to initiate the Wallet Connect instance. Please refresh the page`));
      }
    })
  ), { dispatch: false });


  stakingLodeRequest$ = createEffect(() => this.actions$.pipe(
    ofType<StakeStakingLodeStateAction>(StakingLodeActionTypes.STAKE_STAKING_LODE_STATE_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
       const hasStake = await this.stakingLodeService.stakeLODE(action.user, action.amount);
       return this.store.dispatch(new StakeStakingLodeStateSuccessAction({ user: hasStake }));
      } catch (err) {
        Logger.error(`Error: walletConnectStateInitRequest ${inspect(err)}`);
        return this.store.dispatch(new StakeStakingLodeStateFailureAction(`Failed to stake LODE: ${err.message ?? 'unknown error...'}`));
      }
    })
  ), { dispatch: false });


  usstakingLodeRequest$ = createEffect(() => this.actions$.pipe(
    ofType<UnStakeStakingLodeStateAction>(StakingLodeActionTypes.UNSTAKE_STAKING_LODE_STATE_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
       const hasStake = await this.stakingLodeService.unstakeLODE(action.user, action.amount);
          return this.store.dispatch(new UnStakeStakingLodeStateSuccessAction({ user: hasStake }));
      } catch (err) {
        Logger.error(`Error: walletConnectStateInitRequest ${inspect(err)}`);
        return this.store.dispatch(new UnStakeStakingLodeStateFailureAction(`Failed to initiate the Wallet Connect instance. Please refresh the page`));
      }
    })
  ), { dispatch: false });



  lockLodeRequest$ = createEffect(() => this.actions$.pipe(
    ofType<LockLodeStateAction>(StakingLodeActionTypes.LOCK_LODE_STATE_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
       const lockLode = await this.stakingLodeService.lockLODE(action.user, action.amount);
       return this.store.dispatch(new LockLodeStateSuccessAction({ user: lockLode }));
      } catch (err) {
        Logger.error(`Error: walletConnectStateInitRequest ${inspect(err)}`);
        return this.store.dispatch(new LockLodeStateFailureAction(`Failed to initiate the Wallet Connect instance. Please refresh the page`));
      }
    })
  ), { dispatch: false });


  claimLodeRequest$ = createEffect(() => this.actions$.pipe(
    ofType<ClaimStakingLodeStateAction>(StakingLodeActionTypes.CLAIM_STAKING_LODE_STATE_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
       const claimLode = await this.stakingLodeService.claimRewards(action.user);
       return this.store.dispatch(new ClaimStakingLodeStateSuccessAction({user: claimLode}));
      } catch (err) {
        Logger.error(`Error: walletConnectStateInitRequest ${inspect(err)}`);
        return this.store.dispatch(new ClaimStakingLodeStateFailureAction(`Failed to initiate the Wallet Connect instance. Please refresh the page`));
      }
    })
  ), { dispatch: false });

  approveVoteRequest$ = createEffect(() => this.actions$.pipe(
    ofType<ClaimStakingLodeStateAction>(StakingLodeActionTypes.APPROVE_VOTE_STATE_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
       const approveVote = await this.stakingLodeService.approveVote(action.user);
       return this.store.dispatch(new ApproveVoteStateSuccessAction({user: approveVote}));
      } catch (err) {
        Logger.error(`Error: walletConnectStateInitRequest ${inspect(err)}`);
        return this.store.dispatch(new ApproveVoteStateFailureAction(`Failed to initiate the Wallet Connect instance. Please refresh the page`));
      }
    })
  ), { dispatch: false });


  }