import { Action } from "@ngrx/store";
import { 
  StakingLodeActionTypes,
  StakingLodeStateInitSuccessAction,
  StakeStakingLodeStateFailureAction
} from "../actions/stakingLode.actions";
import { StakingLodeState } from "../store/stakingLode";

export const initialStakingLodeState = {
  userStakingLode: {
      lodeBalance: null,
      hasApprovedLODE: false,
      hasApprovedVote: false,
      hasStaked: false,
      isLocked: false,
      canLock: false,
      stakingInfo: {
        stakingBalance: 0,
        lockedBalance: 0,
        lockDeadline: 0,
      },
      claimingInfo: []
  },
  message: '',
  loading: false,
  error: false
};

export function stakingLodeReducer(
  state: StakingLodeState = initialStakingLodeState,
  action: Action
): StakingLodeState {
  let payload;
  switch (action.type) {
    case StakingLodeActionTypes.STAKING_LODE_STATE_INIT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case StakingLodeActionTypes.STAKING_LODE_STATE_SUCCESS:
      payload = (action as StakingLodeStateInitSuccessAction).payload;
      return {
        ...state,
        userStakingLode: payload.user,
        loading: false,
        error: null
      };
     case StakingLodeActionTypes.STAKING_LODE_STATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Failed to initiate the Wallet Connect instance. Please refresh the page'
      };

     case StakingLodeActionTypes.APPROVE_STAKING_LODE_STATE_SUCCESS:
      payload = (action as StakingLodeStateInitSuccessAction).payload;
      return {
        ...state,
        userStakingLode: payload.user,
        loading: false,
        error: null,
        message: 'LODE allowance set successfully'
      };
     case StakingLodeActionTypes.APPROVE_STAKING_LODE_STATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Failed to approve LODE for staking. Please refresh the page'
      };


     case StakingLodeActionTypes.STAKE_STAKING_LODE_STATE_SUCCESS:
      payload = (action as StakingLodeStateInitSuccessAction).payload;
      return {
        ...state,
        userStakingLode: payload.user,
        loading: false,
        error: null,
        message: 'LODE successfully staked'
      };
     case StakingLodeActionTypes.STAKE_STAKING_LODE_STATE_FAILURE:
      payload = (action as StakeStakingLodeStateFailureAction).payload;
      return {
        ...state,
        loading: false,
        error: true,
        message: payload ?? 'Failed to stake LODE. Please refresh the page'
      };

    case StakingLodeActionTypes.UNSTAKE_STAKING_LODE_STATE_SUCCESS:
      payload = (action as StakingLodeStateInitSuccessAction).payload;
      return {
        ...state,
        userStakingLode: payload.user,
        loading: false,
        error: null,
        message: 'LODE balance successfully unstaked'
      };
     case StakingLodeActionTypes.UNSTAKE_STAKING_LODE_STATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Failed to unstake LODE. Please refresh the page'
      };

    case StakingLodeActionTypes.LOCK_LODE_STATE_SUCCESS:
     payload = (action as StakingLodeStateInitSuccessAction).payload;
      return {
        ...state,
        userStakingLode: payload.user,
        loading: false,
        error: null,
        message: 'LODE balance successfully locked'
      };
     case StakingLodeActionTypes.LOCK_LODE_STATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Failed to lock LODE. Please refresh the page'
      };

    case StakingLodeActionTypes.CLAIM_STAKING_LODE_STATE_SUCCESS:
       payload = (action as StakingLodeStateInitSuccessAction).payload;
      return {
        ...state,
        userStakingLode: payload.user,
        loading: false,
        error: null,
        message: 'Succesfully claimed all claimable rewards'
      };
     case StakingLodeActionTypes.CLAIM_STAKING_LODE_STATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Failed to claim rewards. Please refresh the page'
      };
    case StakingLodeActionTypes.APPROVE_VOTE_STATE_SUCCESS:
             payload = (action as StakingLodeStateInitSuccessAction).payload;
      return {
        ...state,
        userStakingLode: payload.user,
        loading: false,
        error: null,
        message: 'VOT3 token allowance successfully set'
      };
     case StakingLodeActionTypes.APPROVE_VOTE_STATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        message: 'Failed to approve VOT3. Please refresh the page'
      };
    default:
      return state;
  }
}
