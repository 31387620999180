
import { Injectable } from '@angular/core';
import { ERC20_ABI, MAX_INT_ALLOWANCE, TOKEN_ALLOWANCE, GENERATOR_ABI } from '../constants';
import { utils, providers, Contract, BigNumber, constants } from 'ethers';

import { NetworkService } from './network.service';
import { StorageService } from './storage.service';
import { UserGeneratorInformation } from '../../global';
import { Logger } from './logger.service';

@Injectable({
    providedIn: 'root'
})
export class StakingLodeService {
    private network: any;
    private provider: providers.BaseProvider;

    private decimals: number;
    private allowanceThreshold: BigInt;

    private lodeAddress: string;
    private voteAddress: string;
    private generatorAddress: string;

    constructor(
        private storage: StorageService,
        private networkService: NetworkService,    
    ) {
        this.allowanceThreshold = BigInt("77000000000000000000000000");
        this.lodeAddress = "0xbBAAA0420D474B34Be197f95A323C2fF3829E811";
        this.voteAddress = "0x52D6cb3e0DbFc7cA0dccbCda71011B07093FDd31";
        this.generatorAddress = "0x403500ddB8cA54aF62C3546D0d3B70E7cfFB8114";
    }

    async initiateVestingConnection() {
        this.network = await this.networkService.getActiveAvaxNetwork();
        this.provider = providers.getDefaultProvider(this.network.RPC_URL);
    }

    async getUserInformation(user: string): Promise<UserGeneratorInformation> {
        if (!this.provider) { await this.initiateVestingConnection(); }
        return await this._getUserInformation(user);
    }

    async approveLODE(wallet: any): Promise<UserGeneratorInformation> {
        await this._approve(wallet, this.lodeAddress);
        return await this.getUserInformation(wallet.address);    
    }

    async approveVote(wallet: any): Promise<UserGeneratorInformation> {
        await this._approve(wallet, this.voteAddress);
        return await this.getUserInformation(wallet.address);     
    }

    async stakeLODE(wallet: any, amount: number) {
        const generatorInterface = new utils.Interface(GENERATOR_ABI);

        const generator = new Contract(
            this.generatorAddress,
            generatorInterface,
            wallet
        );
        
        const stakingTx = await generator.connect(wallet).stake(utils.parseUnits(amount.toString(), 17));
        const receiptTx = await stakingTx.wait();
        
        return await this.getUserInformation(wallet.address);    
    }

    async lockLODE(wallet: any, amount: number) {
        const generatorInterface = new utils.Interface(GENERATOR_ABI);

        const generator = new Contract(
            this.generatorAddress,
            generatorInterface,
            wallet
        );

        const lockingTx = await generator.connect(wallet).lock(utils.parseUnits(amount.toString(), 17));
        const receiptTx = await lockingTx.wait();

        return await this.getUserInformation(wallet.address);    
    }

    async unstakeLODE(wallet: any, amount: number) {
        const generatorInterface = new utils.Interface(GENERATOR_ABI);

        const generator = new Contract(
            this.generatorAddress,
            generatorInterface,
            wallet
        );

        const unstakingTx = await generator.connect(wallet).unstake(utils.parseUnits(amount.toString(), 17));
        const receiptTx = await unstakingTx.wait();

        return await this.getUserInformation(wallet.address);    
    }

    async claimRewards(wallet: any) {
        const claimableAssets = [
            "0x68327a91E79f87F501bC8522fc333FB7A72393cb",
            "0x13E7bceFddE72492E656f3fa58baE6029708e673",
            "0x0000000000000000000000000000000000000000",
            "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
        ]
        const generatorInterface = new utils.Interface(GENERATOR_ABI);

        const generator = new Contract(
            this.generatorAddress,
            generatorInterface,
            wallet
        );

        // Need to set the array of claimable assets as function input. 
        const claimingTx = await generator.connect(wallet).batchClaim(claimableAssets);
        const receiptTx = await claimingTx.wait();

        return await this.getUserInformation(wallet.address);    
    }

    private async _approve(wallet: any, token: string) {
        const erc20Interface = new utils.Interface(ERC20_ABI);

        const erc20Contract = new Contract(
            token,
            erc20Interface,
            this.provider
        );
        
        const approveTx = await erc20Contract.connect(wallet).approve(this.generatorAddress, constants.MaxUint256);
        const receiptTx = await approveTx.wait();

        return {
            transactionHash: approveTx.hash,
            transactionBlock: receiptTx.blockNumber
        };
    }

    private async _getUserInformation(user: string): Promise<UserGeneratorInformation> {
        const erc20Interface = new utils.Interface(ERC20_ABI);

        const generatorInterface = new utils.Interface(GENERATOR_ABI);
        
        const lode = new Contract(
            this.lodeAddress,
            erc20Interface,
            this.provider
        );

        const vote = new Contract(
            this.voteAddress,
            erc20Interface,
            this.provider
        );

        const generator = new Contract(
            this.generatorAddress,
            generatorInterface,
            this.provider
        );

        const lodeBalance =  await lode.balanceOf(user);
        const lodeDecimals = await lode.decimals();
        
        const lodeAllowance = await lode.allowance(user, this.generatorAddress);
        const voteAllowance = await vote.allowance(user, this.generatorAddress);
        const userPosition = await generator.userBalances(user);

        const claimableAssets = await generator.getClaimableAssets();

        const claimableBalances = [];
        for (let i = 0; i < claimableAssets.length; i++) {
            claimableBalances.push((await generator.getClaimableBalance(user, claimableAssets[i])).amount);
        }

        const claimingInformation = [];
        
        for(let i = 0; i < claimableAssets.length; i++) {
            let decimals: number;

            if(claimableAssets[i] == constants.AddressZero) {
                decimals = 18;
            } else {
                const token = new Contract(
                    claimableAssets[i],
                    erc20Interface,
                    this.provider
                );

                decimals = await token.decimals();
            }

            claimingInformation.push({
                asset: claimableAssets[i],
                balance: parseFloat((Number(claimableBalances[i]) / (10 ** decimals)).toFixed(6)),
            })
        }

        return {
            lodeBalance: parseFloat((Number(lodeBalance) / (10 ** lodeDecimals)).toFixed(6)),
            hasApprovedLODE: parseFloat((Number(lodeAllowance) / (10 ** lodeDecimals)).toFixed(6)) >= parseFloat((Number(this.allowanceThreshold) / (10 ** lodeDecimals)).toFixed(6)),
            hasApprovedVote: parseFloat((Number(voteAllowance) / (10 ** lodeDecimals)).toFixed(6)) >= parseFloat((Number(this.allowanceThreshold) / (10 ** lodeDecimals)).toFixed(6)),
            hasStaked: userPosition?.stakedBalance > 0,
            isLocked: userPosition?.lockedBalance > 0,
            canLock: parseFloat((Number(userPosition?.stakedBalance) / (10 ** lodeDecimals)).toFixed(6)) >= 10000 || userPosition?.lockedBalance > 0,
            stakingInfo: {
                stakingBalance: parseFloat((Number(userPosition?.stakedBalance) / (10 ** lodeDecimals)).toFixed(6)),
                lockedBalance: parseFloat((Number(userPosition?.lockedBalance) / (10 ** lodeDecimals)).toFixed(6)),
                lockDeadline: userPosition?.lockedTimestamp
            },
            claimingInfo: claimingInformation
        }
    }
}